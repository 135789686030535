.upload-container {
  height: 300px;
  width: 100%;
  max-width: 540px;
  background-color: #363636;
  border: 2px dashed #555555;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &.--active {
    background-color: #3c773c36;
  }

  &.--complete {
    background-color: #385982;
  }

  &.--error {
    background-color: #823838;
  }

  p {
    font-weight: bold;
  }
}
