.DayPicker {
  width: 100%;
}

.DayPicker-Day {
  width: 42px;
  height: 42px;
  padding: 2px;
}

.DayPicker-Day--selected {
  color: white;
  background-color: #b36b01 !important;
}

.DayPicker-Day:hover {
  color: black;
  background-color: #dbb67e !important;
}

.DayPicker-TodayButton {
  color: white;
  font-weight: 500;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    color: black;
    background-color: white;
  }
}

.DayPicker-Caption {
  text-transform: none;
  font-size: 0.95rem;
}
