.team-form {
  width: 100%;

  &__avatar_container {
    & .avatar-input__file-drop-zone {
      border-radius: 6px;
      max-width: 300px;
      width: 300px;
      border-color: #ffffff;

      &__drop-comment {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        color: #ffffff;
      }
    }
  }

  &__container {
    background: none;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    text-align: center;
    position: relative;
    height: 10rem;
    width: 10rem;
    z-index: 101;
    overflow: hidden;
    cursor: pointer;

    &.disabled {
      opacity: 0.2;
      cursor: unset;
    }
  }

  & .text-input {
    margin-top: 1rem;
    &__input {
      &:disabled {
        opacity: 1;
        border: none;
        cursor: text;
        padding: 0;
      }
    }
  }
}
