* {
    --chakra-fonts-heading: DINNextLTPro;
    font-family: DINNextLTPro;
    font-weight: 400;
  }
  
  .managed-teams {
    &__header {
      width: 100%;
      height: 20px;
      margin-bottom: 30px;
    }
    &__filters-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: #111;
      height: 80px;
      padding: 20px;
      width: 98%;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 20px;
      border-radius: 8px;
    }
  
    &__team-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      width: 94%;
      color: #ffffff;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }
    &__team-box {
      border-radius: 8px;
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
  
      &__content-background {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 8px;
        position: relative;
  
        &__team-name {
          font-size: 16px;
          font-weight: 400;
          margin-top: 2px;
        }
        &__team-users {
          font-size: 27px;
          font-weight: 400;
          margin-top: 6px;
        }
        &__notification {
          position: absolute;
        }
      }
    }
  }
  
  @media (max-width: 50em) {
    .content-container {
      display: flex;
      flex-direction: column;
    }
  }
  
  @media (max-width: 70em) {
    .content-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }