.description-react-quill {
  border: none;
  border-radius: 8px;
  color: #fff;
  height: auto;

  .ql-container > .ql-editor.ql-blank::before {
    font-size: 14px;
    font-style: normal;
    color: #fff;
  }

  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.16);

    .ql-stroke {
      fill: none;
      stroke: #fff;
    }

    .ql-fill {
      fill: #fff;
      stroke: none;
    }

    .ql-picker {
      color: #fff;
    }
  }
  .ql-editor {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-top: none;
  }
  .ql-container {
    border: none;
  }
}
