.users-container {
  overflow: hidden;

  .search-input {
    margin-right: auto;
  }
}

* {
  --chakra-fonts-heading: DINNextLTPro;
  font-family: DINNextLTPro;
  font-weight: 400;
}

.user-portal {
  margin-top: 40px;

  &__cta {
    height: 550px;
    margin-top: 80px;
    width: 80vh;
    display: flex;
    position: relative;

    &__image {
      position: absolute;
      top: 0;
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin-bottom: 30px;

      color: #ffffff;
    }

    &__button {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
    }
  }

  &__container {
    height: calc(100vh - 100px);
    width: calc(100vw - 1rem) !important;
    max-width: 1440px !important;
    padding: 40px;
    background: #1d1d1d;
    border-radius: 4px;
    min-width: 400px;
    min-height: 600px;
  }
}

.user-tabs {
  &__user_list {
    background-color: #1d1d1d;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    position: relative;

    &__team-info {
      margin-top: var(--chakra-space-2);

      &.can-invite {
        cursor: pointer;
      }

      & h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
      }

      &__avatar {
        border: 1px solid white !important;
        margin-inline-start: var(--chakra-space-3);
        height: 40px !important;
        width: 40px !important;
        background-color: white !important;
      }
    }

    &__tabs {
      min-width: 100%;

      &__tab-list {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        border: none !important;
        margin-inline-start: var(--chakra-space-4);
        margin-inline-end: var(--chakra-space-4);
      }

      &__tab-panels {
        display: flex;
        align-items: center;
        justify-content: space-between !important;

        &__panel {
          padding: var(--chakra-space-4) 0 !important;
          width: 100%;

          &__header {
            margin: 15px 30px 0;

            &__filter {
              border-radius: 4px !important;
              padding-inline-start: 0 !important;
              min-width: 34px !important;
              height: 34px !important;
            }

            &__invite-user {
              border-radius: 4px !important;
              font-style: normal;
              font-weight: 500;
              font-size: 14px !important;
              line-height: 20px;
              color: #ffffff;
            }

            &__search {
              height: 40px;

              & input {
                width: 240px;
              }
            }
          }

          &__table {
            & thead {
              background-color: #1d1d1d;

              & th {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #a0a0a0;
              }
            }

            & tbody {
              & tr {
                background-color: #272727;
                border: none;
                border-bottom: 2px solid #484848;
                border-top: 2px solid #484848;
                height: 60px !important;
                max-height: 60px !important;

                & td {
                  background-color: #272727;
                  border: none;
                  outline: unset;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #a0a0a0;
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;

                  & .chakra-menu__menu-button {
                    height: 30px !important;
                    min-width: 30px !important;
                    background-color: #1d1d1d !important;
                    color: #fff !important;
                  }

                  & .status,
                  & .role {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    &.active {
                      color: #1fb584;
                    }
                    &.pending {
                      color: #ff9901;
                    }
                    &.inactive {
                      color: #e5256a;
                    }
                    &.coach {
                      color: #a541f4;
                    }
                    &.gamer {
                      color: #3eb1f2;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &__tab {
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
      margin-inline-start: var(--chakra-space-4);
      margin-inline-end: var(--chakra-space-4);

      &:last-of-type {
        margin-right: auto;
      }

      &:focus {
        box-shadow: unset !important;
        border-color: var(--chakra-colors-brand-50);
      }
    }
  }
}

.user-table {
  height: 80vh;
  overflow: auto;
}
