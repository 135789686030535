
// styles modification of the calendar picker library
.custom-calendar {
  background-color: #1d1d1d;
  
  .Calendar__monthSelector, .Calendar__yearSelector {
    background-color: #1d1d1d;
  }

  .Calendar__yearSelectorWrapper.-faded::before, .Calendar__yearSelectorWrapper.-faded::after {
    opacity: 0;
  }

  .Calendar__day.-disabled {
    color: var(--chakra-colors-whiteAlpha-300)!important;
  }

  .Calendar__day, .Calendar__monthText, .Calendar__yearText {
    color: var(--chakra-colors-whiteAlpha-800);

    &:hover {
      background-color: var(--chakra-colors-whiteAlpha-200)!important;
      color: var(--chakra-colors-whiteAlpha-800)!important;
    }

    &.-today {
      color: var(--chakra-colors-whiteAlpha-800)!important;

      &:after {
        background-color: var(--chakra-colors-whiteAlpha-800)!important;
      }
    }
  }

  .Calendar__monthArrow {
    filter: invert(100%);
  }
}

.DatePicker__calendarArrow {
  display: none!important;
}
.payments-table {
    height: 80vh;
    overflow: auto;
}
