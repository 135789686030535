  .report-landing {
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
    padding-bottom: 20px;
    // color: '#a0a0a0';
  }

  @media (max-width: 50em) {
    .report-landing {
      font-size: 14px;
      line-height: 19px;
    }
  }