@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextLTPro';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./DINNextLTPro-Light.woff') format('woff'),
    url('./DINNextLTPro-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'DINNextLTPro';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('./DINNextLTPro-Medium.woff') format('woff'),
    url('./DINNextLTPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'DINNextLTPro';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./DINNextLTPro-Medium.woff') format('woff'),
    url('./DINNextLTPro-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'DINNextLTPro';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('./DINNextLTPro-Medium.woff') format('woff'),
    url('./DINNextLTPro-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'DINNextLTPro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./DINNextLTPro-Regular.woff') format('woff'),
    url('./DINNextLTPro-Regular.ttf') format('truetype');
}

$ion-font-family: 'Montserrat';
