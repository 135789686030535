.circle-check {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  height: 20px;
  margin-top: 2px;
  color: var(--chakra-colors-brand-300);
}

.fitgmr-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.logo {
  overflow: hidden;
  position: absolute;
  left: 50px;
  margin-top: 20px;
  line-height: 0;
  z-index: 10;
}

@media (max-width: 70em) {
  html,
  body {
    height: 100%;
    background: #171717 !important;
  }

  .fitgmr-shape {
    height: 100px;
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background: #171717;

    .logo {
      position: relative;
    }

    .button-container {
      margin-bottom: 30px;

      .sign-out-button {
        margin-right: 10vw;
      }
    }
  }

  .body {
    height: unset !important;
  }

  hr {
    width: 100%;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .option-box {
      width: 100%;
      padding: 0 20px;
      margin: 0;

      .box-title {
        justify-content: center;
        align-items: center;
      }

      .description-item {
        margin: 2px 0;

        .check-container {
          width: 20px;
          height: 20px;
        }
      }

      .price-options {
        flex-direction: column;
        padding-left: 20px;
      }

      .option-button {
        margin: 20px auto 40px auto;
      }
    }
  }
}
