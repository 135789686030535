.card-container {
  width: calc(100% - 1rem);
  padding-inline-start: 1rem;
  padding-inline-end: 0;

  & .card {
    transition: all 0.1s ease-out;
    box-sizing: border-box;
    --inactive-border-with: 1px;
    --active-border-with: 1.5px;
  }
  & .card.inactive {
    border-width: var(--inactive-border-with);
    border-color: var(--chakra-colors-whiteAlpha-300);
    border-style: solid;
    border-radius: var(--chakra-radii-sm);
    padding: var(--chakra-space-4);
  }
  & .card.active {
    border-width: var(--active-border-with);
    border-color: #5bd35b;
    border-style: solid;
    border-radius: var(--chakra-radii-sm);
    padding: calc(
      var(--chakra-space-4) -
        calc(var(--active-border-with) - var(--inactive-border-with))
    );
  }

  & .card {
    & .chakra-input {
      border-color: var(--chakra-colors-whiteAlpha-400);
    }
  }
}
