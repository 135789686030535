.trash {
    &__delete-icon {
      color: #fff;
      height: 30px;
      width: 30px;
      margin-bottom: 5px;
    }
  
    &__container {
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 90px;
      background: #f84b75;
    }
  }
  