* {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    width: 3px;
    background: #969696;
  }

  td:last-child {
    border-radius: 0 10px 10px 0;
  }
  td:first-child {
    border-radius: 10px 0 0 10px;
  }

  .table-selected {
    background-color: #272729;
  }
  .table-row {
    cursor: pointer;
    height: 70px;
  }
  .table-row:first-child {
    padding: 16px;
  }
}
