.DayPicker-Month {
  border-collapse: separate;
}
.DayPicker-WeekNumber {
  outline: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
.DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
}
.DayPicker-Day--hoverRange {
  background-color: #8cc0ff !important;
}

.DayPicker-Day--selectedRange {
  background-color: #0062d6 !important;
  border-top-color: #0062d6;
  border-bottom-color: #0062d6;
  border-left-color: #0062d6;
  border-right-color: #0062d6;
}

.DayPicker-Day--selectedRangeStart {
  background-color: #0062d6 !important;
  border-left: 1px solid #0062d6;
}

.DayPicker-Day--selectedRangeEnd {
  background-color: #0062d6 !important;
  border-right: 1px solid #0062d6;
}

.DayPicker-Day--selectedRange.DayPicker-Day--selected,
.DayPicker-Day--hoverRange.DayPicker-Day--selected,
.DayPicker-Day--hoverRange.DayPicker-Day--outside {
  border-radius: 0 !important;
  color: rgb(255, 255, 255) !important;
}
.DayPicker-Day--hoverRange {
  border-radius: 0 !important;
  color: white;
}

.DayPicker-Day--range {
  color: #5bd35b !important;
  font-weight: 500;

  &.DayPicker-Day--hoverRange {
    color: white !important;
  }
}

.DayPicker-Day--outside {
  border-radius: 0 !important;
  cursor: pointer;
  color: #969696;
}

.DayPicker-Day--outside:hover {
  color: white;
}

.DayPicker-Caption {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.25rem;
  color: white;
}

.enhanced-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    width: 3px;
    background: #969696;
  }
}
