.sidebar {
  grid-area: sidebar;
  transition: transform .25s ease-in-out;
  flex-direction: column;
  overflow: unset;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  background-color: #111;

  @media only screen and (max-width: 768px) {
    width: 58px;
    max-width: 58px;
    height: 100vh;
  }

  .sidebar-logo {
    width: 32px;
    margin: 12px;
  
    img {
      width: 100%;
    }
  }
  
  .sidebar-content {
    display: flex;
    align-items: flex-end;
    height: 100%;
    max-width: 100%;
    justify-content: center;
    overflow: hidden;
  
    .dashboard-list {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      max-height: calc(100% - 50px);
      overflow-y: auto;
    }
  
    .dashboard-icon-container {
      padding: 0 12px;
      margin: 8px 0;
      
      &.selected {
        border-left: 2px solid var(--chakra-colors-brand-50);
      }
  
      .dashboard-icon {
        cursor: pointer;
        border-radius: 8px;
    
        img {
          border-radius: 8px;
        }
  
        &.selected {
          outline: 5px solid var(--chakra-colors-whiteAlpha-400);
        }
      }
    }
  
    .add-dashboard {
      background-color: var(--chakra-colors-whiteAlpha-400);
      color: #111;
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      border-radius: 8px;
      margin-top: 8px;
      margin-bottom: 16px;
  
      svg {
        font-size: 24px;
      }
  
      &:hover {
        background-color: var(--chakra-colors-whiteAlpha-700);
      }
    }
  }

  .division {
    height: 2px;
    color: var(--chakra-colors-whiteAlpha-400);
    margin-bottom: 12px;
    max-width: 58px;
  }
  
  .sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 48px;
  }
}

.sidebar-footer-item {
  cursor: pointer;
  color: var(--chakra-colors-whiteAlpha-500)!important;
  margin-top: 16px;
  font-size: 24px;

  &:hover {
    color: var(--chakra-colors-whiteAlpha-700)!important;
  }
}

.mobile-bar {
  display: none;
  @media only screen and (max-width: 768px) {
    transition: transform .25s ease-in-out;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: #111;
    z-index: 1000;

    .sidebar-footer-item {
      margin-left: 16px;
      margin-top: 0;
    }

    &.sidebar-open {
      transform: translateY(-100%);
    }
  }
}
