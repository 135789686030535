.container {
  display: grid;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  grid-template-areas: "sidebar navigation content";
  grid-template-columns: fit-content(0%) fit-content(0%) 1fr;
  grid-template-rows: 100vh;

  @media only screen and (max-width: 768px) {
    grid-template-areas: "content";
    grid-template-columns: 1fr;

    .navigation, .sidebar {
      z-index: 1000;
      transform: translate(-150%);
      position: absolute;
      top: 0;
    }

    &.sidebar-open {
      .navigation, .sidebar {
        transform: translate(0%);
      }
    }
  }
}

.contentContainer {
  overflow: auto;
  width: calc(100vw - 258px);
  grid-area: content;
  padding: 24px;
  transition: width 250ms ease-out;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: calc(100vw)!important;
    padding-top: 74px;
  }

  &.navBarCollapsed {
    width: calc(100vw - 118px);
  }
}