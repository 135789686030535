.avatar-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;

  &__container {
    background: none;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    text-align: center;
    position: relative;
    height: 10rem;
    width: 10rem;
    z-index: 101;
    overflow: hidden;
    cursor: pointer;

    &.disabled {
      opacity: 0.2;
      cursor: unset;
    }

    &__reset {
      z-index: 100;
      width: 100%;
      height: 100%;
      transition: all 100ms ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        transition: all 100ms ease-in-out;
        opacity: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);

        .avatar-input__container__reset__icon {
          opacity: 1;
        }
      }
    }
  }

  &__file-drop-zone {
    border: 1px dashed var(--chakra-colors-brand-300);
    border-radius: 50%;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: 10rem;
    cursor: pointer;
    outline: none;

    & > p {
      margin: 0;
      padding: 0 10px;
    }

    &__drop-comment {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;
      flex: 1 1 50%;

      /* Bright/Blue */

      &__icon {
        color: var(--chakra-colors-brand-300);
        background-color: transparent !important;
      }
    }

    &__allowed_files {
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-align: center;

      /* Grey/Text Blue */

      opacity: 0.9;

      margin-top: 6px;
    }
  }
}
