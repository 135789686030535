.navigation {
  grid-area: navigation;
  transition: transform .25s ease-out;

  @media only screen and (max-width: 768px) {
    left: 58px;
    height: 100vh;
  }

  .navigation-wrapper {
    width: 200px;
    height: 100%;
    transition: width .3s ease-out;
    background-color: #1d1d1d;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      width: 200px!important;

      .arrow {
        display: none;
      }

      .collapsed {
        opacity: 1!important;
      }
    }
    
    &.collapsed {
      width: 60px;
    }
    
    .avatar-container {
      width: 100%;
      
      .avatar-profile {
        position: relative;
        display: flex;
        margin: 12px;
        align-items: center;

        .profile-info-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          transition: opacity .25s ease-in;

          .profile-title {
            color: var(--chakra-colors-whiteAlpha-700);
            align-self: flex-start;
            margin-left: 10px;
            white-space: nowrap;
            max-width: 134px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .profile-role {
            color: var(--chakra-colors-whiteAlpha-500);
            font-size: 12px;
          }

          &.collapsed {
            opacity: 0;
            transition: opacity .1s 50ms ease-out;
          }
        }
  
        .arrow {
          position: absolute;
          top: 0;
          right: -25px;
          border-radius: 4px;
          padding: 8px 2px 8px 8px;
          background-color: #1d1d1d;
          cursor: pointer;
          z-index: 10;
  
          svg {
            font-size: 12px;
          }
        }
      }
    }

    .delete-section {
      display: flex;
      align-items: flex-end;
      height: 100%;
      margin-bottom: 12px;

      .button {
        background: none;
      }
    }

    .navigation-option {
      margin-left: 8px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      cursor: pointer;
      width: calc(100% - 16px);
      border-radius: 8px;

      svg {
        font-size: 24px;
        min-width: 24px;
        // width: auto;
        height: auto;
        color: var(--chakra-colors-whiteAlpha-500);
      }

      .option-title {
        color: var(--chakra-colors-whiteAlpha-500);
        margin-left: 10px;
        white-space: nowrap;
        transition: opacity .25s ease-in;

        &.collapsed {
          opacity: 0;
          transition: opacity .1s 50ms ease-out;
        }
      }

      &:hover {
        svg, .option-title {
          color: var(--chakra-colors-whiteAlpha-700);
        }
      }

      &.selected {
        background-color: var(--chakra-colors-whiteAlpha-200);

        svg, .option-title {
          color: var(--chakra-colors-whiteAlpha-900);
        }
      }

      &.notification {
        svg {
          min-width: 28px;
        }
      }
    }
  }
}

.overlay {
  z-index: 900;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: var(--chakra-colors-blackAlpha-500);
}
